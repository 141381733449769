import { CustomFieldValueModel } from "./CustomFieldValueModel"
import { ItemSnapshotModel } from "./ItemSnapshotModel"
import { ScoreCardModel } from "./ScoreCardModel"
import { ItemKeywordMatchModel } from "./ItemKeywordMatchModel"
import { ItemMonitorModel } from "./ItemMonitorModel"
import { ItemAlertsModel } from "./ItemAlertsModel"

export class CatalogItemModel {
  public id?: string = ""
  public workspaceId?: string = ""
  public itemIdentifier?: string = ""
  public jobName?: string = ""
  public itemCreatedAt: string = ""
  public primaryKeywords: string[] = []
  public secondaryKeywords: string[] = []
  public retailerName?: string = ""
  public category?: string = ""
  public firstSubCategory?: string = ""
  public secondSubCategory?: string = ""
  public tags: string[] = []
  public flagsCount: number | string = ""
  public criticalFlagsCount: number | string = ""
  public warningFlagsCount: number | string = ""
  public indicatorFlagsCount: number | string = ""
  public triggeredAlertsIds: string[] = []
  public customFields: CustomFieldValueModel[] = []
  public itemSnapshotCreatedAt: string = ""
  public currentSnapshot: ItemSnapshotModel = {} as ItemSnapshotModel
  public currentSnapshotScoreCard: ScoreCardModel = {} as ScoreCardModel
  public keywordMatches: ItemKeywordMatchModel = {} as ItemKeywordMatchModel
  public itemMonitor: ItemMonitorModel = {} as ItemMonitorModel
  public criticalAlerts: ItemAlertsModel[] = []
  public indicatorAlerts: ItemAlertsModel[] = []
  public warningAlerts: ItemAlertsModel[] = []
  public masterVersionCount: number | string = ""
  public masterVersionAccuracy: number | string = ""
  public status: string = ""
  constructor(obj = {} as any) {
    obj = obj || {}
    this.id = obj.id === null ? "" : obj.id
    this.workspaceId = obj.workspaceId === null ? "" : obj.workspaceId
    this.itemIdentifier = obj.itemIdentifier === null ? "" : obj.itemIdentifier
    this.jobName = obj.jobName === null ? "" : obj.jobName
    this.itemCreatedAt = obj.itemCreatedAt === null ? "" : obj.itemCreatedAt
    this.primaryKeywords = obj.primaryKeywords || []
    this.secondaryKeywords = obj.secondaryKeywords || []
    this.retailerName = obj.retailerName === null ? "" : obj.retailerName
    this.category = obj.category === null ? "" : obj.category
    this.firstSubCategory = obj.firstSubCategory === null ? "" : obj.firstSubCategory
    this.secondSubCategory = obj.secondSubCategory === null ? "" : obj.secondSubCategory
    this.tags = obj.tags || []
    this.flagsCount = obj.flagsCount === null ? "" : obj.flagsCount
    this.criticalFlagsCount =
      obj.criticalFlagsCount === null ? "" : obj.criticalFlagsCount
    this.warningFlagsCount = obj.warningFlagsCount === null ? "" : obj.warningFlagsCount
    this.indicatorFlagsCount =
      obj.indicatorFlagsCount === null ? "" : obj.indicatorFlagsCount
    this.triggeredAlertsIds = obj.triggeredAlertsIds || []
    this.customFields =
      obj.customFields
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new CustomFieldValueModel(item)) || []
    this.itemSnapshotCreatedAt =
      obj.itemSnapshotCreatedAt === null ? "" : obj.itemSnapshotCreatedAt
    this.currentSnapshot = !obj.currentSnapshot
      ? new ItemSnapshotModel()
      : new ItemSnapshotModel(obj.currentSnapshot)
    this.currentSnapshotScoreCard = !obj.currentSnapshotScoreCard
      ? new ScoreCardModel()
      : new ScoreCardModel(obj.currentSnapshotScoreCard)
    this.keywordMatches = !obj.keywordMatches
      ? new ItemKeywordMatchModel()
      : new ItemKeywordMatchModel(obj.keywordMatches)
    this.itemMonitor = !obj.itemMonitor
      ? new ItemMonitorModel()
      : new ItemMonitorModel(obj.itemMonitor)
    this.criticalAlerts =
      obj.criticalAlerts
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new ItemAlertsModel(item)) || []
    this.indicatorAlerts =
      obj.indicatorAlerts
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new ItemAlertsModel(item)) || []
    this.warningAlerts =
      obj.warningAlerts
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new ItemAlertsModel(item)) || []
    this.masterVersionCount =
      obj.masterVersionCount === null ? "" : obj.masterVersionCount
    this.masterVersionAccuracy =
      obj.masterVersionAccuracy === null ? "" : obj.masterVersionAccuracy
    this.status = obj.status === null ? "" : obj.status
  }
}
